<template>
  <div class="add-food" ref="addFood">
    <div class="st-wrapper">
      <div class="card">
        <div class="field" @click="selectFood" required data-label="name">
          <div class="name">
            <s>*</s>
            <span>食材名称</span>
          </div>
          <div class="right">
            <input
              v-model="sendData.name"
              type="text"
              placeholder="例如：黄瓜"
              readonly
            />
            <img src="../../../../assets/images/icon/arrow-r-m-03.png" alt="" />
          </div>
        </div>
        <div
          class="field"
          v-if="$route.query.supplyId === null"
          @click="selectSupply"
        >
          <div class="name">
            <s>*</s>
            <span>供应商</span>
          </div>
          <div class="right">
            <input
              v-model="sendData.supplyName"
              type="text"
              placeholder="请选择"
              readonly
            />
            <img src="../../../../assets/images/icon/arrow-r-m-03.png" alt="" />
          </div>
        </div>
        <div
          class="field"
          @click="categoryVisible = true"
          required
          data-label="catalog"
        >
          <div class="name">
            <s>*</s>
            <span>食材品类</span>
          </div>
          <div class="right">
            <input
              v-model="sendData.catalog"
              type="text"
              placeholder="例如：蔬菜"
              readonly
            />
            <img src="../../../../assets/images/icon/arrow-r-m-03.png" alt="" />
          </div>
        </div>
        <div class="field">
          <div class="name">
            <span>*</span>
            <span>食材图片</span>
          </div>
        </div>
        <div class="field upload-field">
          <van-uploader
            class="upload"
            v-model="fileList"
            :after-read="uploader"
            :max-count="1"
            @delete="hanldeDelImg"
          >
            <div class="upload-wrap">
              <img
                src="../../../../assets/images/icon/entry-upload.png"
                alt=""
              />
            </div>
          </van-uploader>
          <span v-show="fileList.length === 0">请上传食材图片</span>
        </div>
      </div>
      <footer-button text="确定" @confirm="confirm" />
    </div>
    <!-- 食材名称 弹出层 -->
    <van-popup v-model="foodVisible" round position="bottom" lazy-render>
      <select-list
        placeholder="输入食材名称进行搜索"
        :list="foodList"
        @confim="handleFood"
      />
      <!-- <van-picker
        show-toolbar
        value-key="name"
        :columns="foodList"
        @cancel="foodVisible = false"
        @confirm="onFoodConfirm"
      /> -->
    </van-popup>
    <!-- 供应商 弹出层 -->
    <van-popup v-model="supplierVisible" round position="bottom">
      <van-picker
        show-toolbar
        value-key="name"
        :columns="supplierList"
        @cancel="supplierVisible = false"
        @confirm="onSupplierConfirm"
      />
    </van-popup>
    <!-- 食材品类 弹出层 -->
    <van-popup v-model="categoryVisible" round position="bottom">
      <van-picker
        show-toolbar
        value-key="name"
        :columns="categoryList"
        @cancel="categoryVisible = false"
        @confirm="onCategoryConfirm"
      />
    </van-popup>
  </div>
</template>
<script>
import { Popup, Picker, Uploader, Toast } from 'vant'
import FooterButton from '../../../../components/footer/button/Index'
import selectList from '../../../../components/select/list/Index'
export default {
  components: {
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Uploader.name]: Uploader,
    FooterButton,
    selectList
  },
  data () {
    return {
      // 食材名称弹出层
      foodVisible: false,
      foodList: [],
      // 供应商 弹出层
      supplierVisible: false,
      supplierList: [],
      // 食材品类 弹出层
      categoryVisible: false,
      categoryList: [],
      // 食材图片
      fileList: [],
      sendData: { // 新增所需 传送数据
        _id: null, // 食材Id
        name: '', // 食材名
        cid: null, // 所属品类id
        catalog: '', // 所属品类
        supplyId: null,
        supplyName: '',
        imageUrl: '' // 食材图片地址
      }
    }
  },
  created () {
    const { supplyId, foodInfo } = this.$route.query
    if (!supplyId) {
      this.getSupplierList()
    } else {
      this.sendData.supplyId = supplyId
      this.getFoodList(supplyId)
    }
    if (foodInfo) { // 存在就是编辑
      // console.log(foodInfo)
      const { name, catalog, imageUrl } = foodInfo
      this.sendData._id = foodInfo._id
      this.sendData.name = name
      this.sendData.catalog = catalog
      this.sendData.imageUrl = imageUrl
      this.fileList.push({
        url: imageUrl
      })
    }
    this.getCategoryList()
  },
  methods: {
    // 获取 食材 列表
    getFoodList (supplyId) {
      this.$api.supplier.foodList({
        supplyId: supplyId
      }).then(res => {
        // console.log('食材列表:', res)
        if (res.data.success) {
          this.foodList = res.data.data || []
        }
      })
    },
    // 获取 供应商列表
    getSupplierList () {
      this.$api.supplier.list({
        bid: this.$route.query.bid
      }).then(res => {
        // console.log('供应商列表:', res)
        if (res.data.success) {
          this.supplierList = res.data.result.map(item => {
            return {
              ...item
            }
          })
        }
      })
    },
    // 获取 食材品类列表
    getCategoryList () {
      this.$api.supplier.categoryList().then(res => {
        // console.log('食材品类列表:', res)
        if (res.data.success) {
          this.categoryList = res.data.result
        }
      })
    },
    // 食材 选择 picker
    onFoodConfirm (item) {
      // console.log(item)
      this.sendData.name = item.name
      this.foodVisible = false
    },
    // 供应商 选择 picker
    onSupplierConfirm (item) {
      // console.log(item)
      this.sendData.supplyId = item._id
      this.sendData.supplyName = item.name
      this.supplierVisible = false
      this.getFoodList(item._id)
    },
    // 食材品类 选择 picker
    onCategoryConfirm (item) {
      // console.log(item)
      this.sendData.cid = item._id
      this.sendData.catalog = item.name
      this.categoryVisible = false
    },
    // 点击 展示 供应商列表
    selectSupply () {
      if (this.supplierList === null || this.supplierList.length === 0) {
        Toast('暂无供应商')
        return false
      }
      this.supplierVisible = true
    },
    // 点击 展示 食材列表
    selectFood () {
      if (this.sendData.supplyId === null) {
        Toast('请先选择供应商')
        return false
      }
      this.foodVisible = true
    },
    // 上传 图片
    uploader (file) {
      const formData = new FormData()
      formData.append('file', file.file, file.file.name)
      this.$api.entry.uploaders(formData).then(res => {
        console.log(res)
        if (res.status === 200) {
          this.sendData.imageUrl = res.data.url
        }
      })
    },
    // 图片 删除
    hanldeDelImg (file) {
      this.sendData.imageUrl = ''
    },
    // 修改食材名称
    handleFood (val) {
      this.sendData.name = val
      this.foodVisible = false
    },
    // 提交
    confirm () {
      const fields = this.$refs.addFood.querySelectorAll('.field')
      for (let i = 0; i < fields.length; i++) {
        const item = fields[i]
        if (item.getAttribute('required')) {
          if (this.sendData[item.getAttribute('data-label')] === '') {
            Toast({ message: '请填写' + item.querySelector('span').innerHTML })
            return false
          }
        }
      }

      if (this.sendData.imageUrl === '' || this.sendData.imageUrl === null) {
        Toast({ message: '请上传食材图片' })
        return false
      }

      // console.log(this.sendData)
      const { bid, title, type, supplyId, foodInfo } = this.$route.query
      // console.log(this.$route.query)
      if (foodInfo) {
        this.$api.supplier.editFood(this.sendData).then(res => {
          // console.log(res)
          if (res.data.success) {
            this.$router.replace({
              name: 'FoodList',
              query: {
                bid, title, type, supplyId
              }
            })
          }
        })
      } else {
        const { catalog, supplyId, supplyName } = this.sendData
        this.$api.supplier.addFood(this.sendData).then(res => {
          // console.log(res)
          if (res.data.success) {
            this.$router.replace({
              name: 'FoodList',
              query: {
                bid,
                title: supplyName || title,
                type: catalog,
                supplyId: supplyId
              }
            })
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
