<template>
  <div class="select">
    <div class="search">
      <div class="wrap">
        <input
          v-model="val"
          type="text"
          @input="input"
          :placeholder="placeholder"
        />
        <span @click="onConfim(null)">确定</span>
      </div>
    </div>
    <div class="list st-wrapper">
      <div
        class="st-item"
        v-for="(item, index) in newList1"
        :key="index"
        @click="onConfim(item[attr])"
      >
        {{ item[attr] }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: '请输入要搜索的内容'
    },
    list: {
      type: Array,
      default: function () {
        return []
      }
    },
    attr: {
      type: String,
      default: 'name'
    }
  },
  computed: {
    newList1 () {
      return this.newList
    }
  },
  data () {
    return {
      oldList: [],
      newList: [],
      val: ''
    }
  },
  created () {
    this.val = ''
    this.newList = this.oldList = JSON.parse(JSON.stringify(this.list)) || []
  },
  methods: {
    input () {
      // console.log(this.val)
      let list = this.oldList
      list = list.filter(item => {
        return item[this.attr].indexOf(this.val) !== -1
      })
      this.newList = list
    },
    onConfim (val) {
      const value = val || this.val
      this.$emit('confim', value)
    }
  }
}
</script>

<style lang="less" scoped >
@import './index.less';
</style>
